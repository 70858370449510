<div class="floors">
  <!-- <div class="floor-label">Triplewide Manufactured Homes</div> -->
  <h1>Floor Plans for Double wide Mobile Homes</h1>
</div>

<!-- <h2>What are triple wide manufactured homes?</h2>
  <p>
    Triple wide homes come in 3 separate sections, built in a factory and shipped
    to your homesite. Marlette builds triple wides under the Majestic Brand -
    their top of the line series which include many luxurious features as
    standard.
  </p> -->

<iframe
  allowtransparency="true"
  title="Wistia video player"
  allowFullscreen
  frameborder="0"
  scrolling="no"
  class="wistia_embed"
  name="wistia_embed"
  src="https://fast.wistia.net/embed/iframe/2c5k2n140c"
  width="400"
  height="225"
></iframe>

<h2>How much do double wide mobile homes cost?</h2>
<p>
  Doublewide homes are an excellent option for those looking for an affordable,
  yet spacious living space. These homes are transported in two sections and are
  typically wider and more spacious than single-wide homes but still very
  affordable. Prices for our doublewide homes start at 130K, which does not
  includes delivery (some exceptions apply for - discuss this with your sales
  consultant). We believe that everyone deserves a comfortable, high-quality
  home, which is why we strive to offer competitive new home prices that also
  qualify for competitive financing.
</p>

<h2>Are these homes as good as a site or stick built homes?</h2>
<p>
  At Heritage Home Center, we are committed to building homes that are not only
  beautiful but also built to last. Most of our series homes are constructed
  using only the highest-quality materials, including
  <b
    >2x6" exterior walls, 2x4" interior walls, and 2x10" grade-A lumber for the
    floors.</b
  >
  We believe that a well-built home should stand the test of time, which is why
  we take great care to ensure that every detail is considered during
  construction. From the foundation to the finishing touches, our homes are
  crafted with precision and care to provide you with a safe, comfortable living
  space that you and your family can enjoy for years to come. Trust the Marlette
  brand for quality construction and exceptional value in every home we build.
</p>

<h2>What else can I customize when I buy a double wide home?</h2>
<p>
  Our doublewide manufactured homes (mobile homes) offer a good starting point
  for a base floor plan that can be customized to meet your unique style and
  preferences. Choose from a range of options, including vaulted loft-style
  ceilings, large windows, cabinets, solid surface countertops, walk-in pantry,
  extra tall windows, glass tile finishes, roomy bathrooms with walk-in showers,
  and jacuzzi tubs. We also offer upgraded window treatments and floor coverings
  to add the finishing touches and personalized just for you. At Heritage Home
  Center, we understand that your home is an investment, which is why we are
  committed to providing you with high-quality, customizable options that not
  only fit your budget, but also your lifestyle."
</p>

<mat-spinner *ngIf="isLoading; else floorPlanListings"></mat-spinner>

<ng-template #floorPlanListings>
  <div class="floorplanlist-container">
    <app-floorplan-box
      *ngFor="let floorplan of filteredHomes"
      [floorplan]="floorplan"
    >
    </app-floorplan-box>
  </div>
</ng-template>

<div class="on-display-title">
  <a routerLink="/manufactured-home-singlewides"> Show Me Single Wides </a>
</div>

<div class="on-display-title">
  <a routerLink="/manufactured-home-triplewides"> Show Me Triple Wides </a>
</div>

<div class="on-display-title">
  <a routerLink="/floorplans"> Show Me All Homes </a>
</div>
